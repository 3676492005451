<template>
  <Sidebar
    v-model:visible="advancedFilterVisible"
    class="advanced-filters-sidebar"
    header="Filters"
    position="right"
  >
    <div class="flex flex-col h-full">
      <InputWrapper
        name="type"
        label="course_results.type"
      >
        <MultiSelect
          v-model="searchFilter.type"
          required
          display="chip"
          :options="tradTypeFilterOptions"
          :show-toggle-all="false"
          field="type"
          icon="fa fa-filter"
          name="type"
          data-key="key"
          option-label="label"
          :placeholder="$t('general.select')"
        />
      </InputWrapper>
      <InputWrapper
        required
        name="price"
        label="course_results.cost"
      >
        <MultiSelect
          v-model="searchFilter.price"
          required
          display="chip"
          :options="tradPriceSortOptions"
          :show-toggle-all="false"
          field="price"
          icon="fa fa-filter"
          name="price"
          data-key="key"
          option-label="label"
          :placeholder="$t('general.select')"
        />
      </InputWrapper>
      <InputWrapper
        required
        name="level"
        label="course_results.level"
      >
        <MultiSelect
          v-model="searchFilter.level"
          display="chip"
          :options="tradLevelSortOptions"
          :show-toggle-all="false"
          field="level"
          icon="fa fa-filter"
          name="level"
          data-key="key"
          option-label="label"
          :placeholder="$t('general.select')"
        />
      </InputWrapper>
      <InputWrapper
        required
        name="language"
        label="course_results.language"
      >
        <MultiSelect
          v-model="searchFilter.language"
          display="chip"
          :options="tradLanguageSortOptions"
          :show-toggle-all="false"
          field="language"
          icon="fa fa-filter"
          name="language"
          data-key="key"
          option-label="label"
          :placeholder="$t('general.select')"
        />
      </InputWrapper>
      <InputWrapper
        required
        name="provider"
        type="text"
        label="course_results.provider"
      >
        <MultiSelect
          v-model="searchFilter.provider"
          display="chip"
          :options="tradProviderSortOptions"
          :show-toggle-all="false"
          field="provider"
          icon="fa fa-filter"
          name="provider"
          data-key="key"
          option-label="label"
          :placeholder="$t('general.select')"
        />
      </InputWrapper>
      <div class="flex flex-col mt-auto gap-4">
        <Button
          class="flex justify-center"
          :label="$t('course_results.filters')"
          @click="updateFilters()"
        />
        <Button
          v-if="filtersApplied > 0"
          class="flex justify-center"
          :label="$t('course_results.reset_filters')"
          severity="secondary"
          @click="resetFiltersTriggerSearch()"
        />
      </div>
    </div>
  </Sidebar>
</template>
<script setup>
import { ref, defineModel, defineEmits, defineProps, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Button from 'primevue/button';
import Sidebar from "primevue/sidebar";
import {
  typeFilterOptions,
  priceSortOptions,
  levelFilterOptions,
} from '@/composables/useElasticCourseResults'
import useCourseProviders from "@/composables/useCourseProviders";
import { LOCALES } from "@/composables/userData";
import {
  useCourseResults,
} from '@/composables/useElasticCourseResults'
import InputWrapper from "@/components/common/InputWrapper.vue";
import MultiSelect from "primevue/multiselect";

const props = defineProps({
  filters: { type: Object, required: true },
})

const { courseProviders } = useCourseProviders()
const { languagesList } = useCourseResults()
const { t } = useI18n();

const defaultFilters = {
  price: '',
  level: '',
  language: '',
  provider: '',
  type: '',
}

const emit = defineEmits(['updateFilters'])
const advancedFilterVisible = defineModel()

const updateFilters = () => {
  const result = {...searchFilter.value}
  for (const key in result) {
    if (Array.isArray(result[key]) && result[key].length === 0) {
      result[key] = ''
    }
  }
  emit('updateFilters', { filters: result })
  advancedFilterVisible.value = false
}

const resetFiltersTriggerSearch = () => {
  searchFilter.value = {...defaultFilters}
  filtersApplied.value = 0
  updateFilters()
}

const tradTypeFilterOptions = typeFilterOptions.map((option) => ({ key: option.value, label: t(option.label) }))
const tradPriceSortOptions = priceSortOptions.map((option) => ({ key: option.value, label: t(option.label) }))
const tradLevelSortOptions = levelFilterOptions.map((option) => ({ key: option.value, label: t(option.label) }))
const tradProviderSortOptions = Object.entries(courseProviders).map(([key, option]) => ({ key: key, label: option.label }))
const tradLanguageSortOptions = languagesList.value ? languagesList.value.map((option) => {
  return { key: option.key, label: option.label }
}) : LOCALES;
const searchFilter = ref(props.filters || defaultFilters)
const filtersApplied = computed(() => Object.keys(searchFilter.value).filter(key => searchFilter.value[key] !== defaultFilters[key]).length)

watch(() => props.filters, (newFilters) => {
  searchFilter.value = newFilters
})
</script>
