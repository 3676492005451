<template>
  <div class="page-body">
    <TopBarRegister :language="language" />
    <div :class="(step2 || step3)?'register wider':'register'">
      <div v-if="step1 && !is_loading">
        <div class="surface-card p-4 shadow-2 border-round w-full ">
          <div class=" mb-5">
            <h3>{{ $t('register.title') }}</h3>
            <span class="register-subtitle">
              <i class="fas fa-check blue" /> {{ $t('register.sub_line_1') }}
            </span>
            <span class="register-subtitle">
              <i class="fas fa-check blue" /> {{ $t('register.sub_line_2') }}
            </span>
            <span class="register-subtitle">
              <i class="fas fa-check blue" /> {{ $t('register.sub_line_3') }}
            </span>
          </div>
          <div
            v-if="email_exists"
            class="main-error"
          >
            <div>
              <i
                style="margin-right:8px"
                class="fas fa-exclamation-triangle"
              /> {{ $t('register.same_email') }}
            </div>
            <div
              class="error-sign"
              @click="openUrl(login_url)"
            >
              {{ $t('register.access') }}
            </div>
          </div>
          <div>
            <Button
              id="social-google"
              :label="$t('register.google_register')"
              class="social-link"
              @click="getGoogleData"
            />
            <Button
              id="social-linkedin"
              :label="$t('register.linkedin_register')"
              class="social-link"
              @click="linkedInLogin"
            />
          </div>
          <div class="separator">
            {{ $t('register.separator') }}
          </div>
          <div>
            <label
              for="email"
              class="block text-900 font-medium mb-2"
            >
              Email
            </label>
            <InputText
              id="email"
              v-model="email"
              :invalid="is_invalid_email"
              type="text"
              placeholder="Email address"
              :class="is_invalid_email?'w-full mb-3 input-error':'w-full mb-3'"
            />
            <small
              v-if="is_invalid_email"
              id="email-error"
              class="error"
            >
              {{ email_error }}
            </small>
          </div>
          <div>
            <label
              for="password"
              class="block text-900 font-medium mb-2"
            >
              {{ $t('register.create_password') }}
            </label>
            <Password
              id="password"
              v-model="password"
              type="password"
              :invalid="is_invalid_password"
              placeholder="Password"
              :feedback="false"
              :toggle-mask="true"
              :class="is_invalid_password?'w-full mb-3 p-full-width input-error':'w-full mb-3 p-full-width'"
            >
              <template #header>
                <h3>{{ $t('register.choose_password') }}</h3>
              </template>
            </Password>
            <div v-if="is_invalid_password">
              <small
                id="password-error"
                class="error"
              >
                {{ password_error }}
              </small>
            </div>
            <small
              id="password-help"
              :class="(is_invalid_password && password_error.length === 0 )?'error':''"
            >
              {{ $t('general.form.field_errors.password') }}
            </small>
          </div>
          <Button
            :label="$t('register.continue')"
            class="w-full register-page-button"
            @click="validateFields"
          />
        </div>
      </div>
      <div
        v-if="step2 && !is_loading"
        id="page2"
        class="page-2"
      >
        <div>
          <span
            class="link-back"
            @click="moveToStep(1)"
          >
            <i class="fas fa-arrow-left" />
            {{ $t('register.back') }}
          </span>
        </div>
        <div class="surface-card p-4 shadow-2 border-round w-full ">
          <div class="mb-5">
            <h3>{{ $t('register.title_2') }}</h3>
          </div>
          <div
            v-if="!first_name_valid || !last_name_valid || !identifichi_valid || !obbiettivo_valid || !azienda_valid || !dipendenti_valid || !privacy_policy_valid"
            class="main-error"
          >
            <div>
              <i
                style="margin-right:8px"
                class="fas fa-exclamation-triangle"
              />
              {{ $t('register.mandatory') }}
            </div>
          </div>
          <div class="register-form-line">
            <div class="half-block">
              <label
                for="name"
                class="block text-900 font-medium mb-2 label-small"
              >
                {{ $t('register.first_name') }}
              </label>
              <InputText
                id="name"
                v-model="first_name"
                :disabled="first_name_disabled"
                type="text"
                :placeholder="$t('register.first_name')"
                :class="!first_name_valid?'w-full mb-3 input-error':'w-full mb-3'"
              />
            </div>
            <div class="half-block">
              <label
                for="surname"
                class="block text-900 font-medium mb-2 label-small"
              >
                {{ $t('register.last_name') }}
              </label>
              <InputText
                id="surname"
                v-model="last_name"
                :disabled="last_name_disabled"
                type="text"
                :placeholder="$t('register.last_name')"
                :class="!last_name_valid?'w-full mb-3 input-error':'w-full mb-3'"
              />
            </div>
          </div>
          <div class="register-form-line">
            <div class="half-block">
              <label
                for="identifichi"
                class="block text-900 font-medium mb-2 label-small"
              >
                {{ $t('register.identify') }}
              </label>
              <Dropdown
                id="identifichi"
                v-model="identifichi"
                type="text"
                append-to="#page2"
                :options="dropdown_identifichi"
                option-label="label"
                :placeholder="$t('register.select')"
                :class="!identifichi_valid?'w-full mb-3 input-error':'w-full mb-3'"
              />
            </div>
            <div class="half-block">
              <label
                for="obbiettivo"
                class="block text-900 font-medium mb-2 label-small"
              >
                {{ $t('register.objective') }}
              </label>
              <Dropdown
                id="obbiettivo"
                v-model="obbiettivo"
                type="text"
                append-to="#page2"
                :options="dropdown_obbiettivo"
                option-label="label"
                :placeholder="$t('register.select')"
                :class="!obbiettivo_valid?'w-full mb-3 input-error':'w-full mb-3'"
              />
            </div>
          </div>
          <div class="register-form-line">
            <div class="half-block">
              <label
                for="azienda"
                class="block text-900 font-medium mb-2 label-small"
              >
                {{ $t('register.company') }}
              </label>
              <InputText
                id="azienda"
                v-model="azienda"
                type="text"
                :placeholder="$t('register.company')"
                :class="!azienda_valid?'w-full mb-3 input-error':'w-full mb-3'"
              />
            </div>
            <div class="half-block">
              <label
                for="dipendenti"
                class="block text-900 font-medium mb-2 label-small"
              >
                {{ $t('register.company_size') }}
              </label>
              <Dropdown
                id="dipendenti"
                v-model="dipendenti"
                append-to="#page2"
                :options="dropdown_dipendenti"
                option-label="label"
                type="text"
                :placeholder="$t('register.select')"
                :class="!dipendenti_valid?'w-full mb-3 input-error':'w-full mb-3'"
              />
            </div>
          </div>
          <div class="flex align-items-center">
            <Checkbox
              v-model="privacy_policy"
              :binary="true"
              input-id="privacy"
              name="privacy"
            />
            <label
              for="privacy"
              :class="!privacy_policy_valid?'ml-2 input-error':'ml-2'"
            >
              {{ $t('register.privacy_policy') }}
              <a
                target="_blank"
                :href="linkTerms"
              >
                {{ $t('register.tec') }}
              </a>
              {{ $t('register.and') }}
              <a
                target="_blank"
                :href="linkPrivacy"
              >
                privacy policy
              </a>
            </label>
          </div>
          <div class="flex align-items-center">
            <Checkbox
              v-model="news_letter"
              :binary="true"
              input-id="newsletter"
              name="newsletter"
            />
            <label
              for="newsletter"
              class="ml-2"
            >
              {{ $t('register.newsletter') }}
            </label>
          </div>
          <Button
            :label="$t('register.complete')"
            class="w-full register-page-button"
            @click="validateFieldsStep2"
          />
        </div>
      </div>
      <div
        v-if="is_loading"
        class="spin-container"
      >
        <div class="wait">
          {{ $t('register.wait') }}
        </div>
        <div class="process">
          {{ $t('register.process') }}
        </div>
        <img
          src="../assets/oval.svg"
          class="spinner fa-spin"
        >
      </div>
      <div
        v-if="step3 && !is_loading"
        class="page-2"
      >
        <div class="surface-card p-4 shadow-2 border-round w-full">
          <div v-if="email_verified">
            <div class="mb-5 welcome-outer">
              <div class="welcome-title">
                {{ $t('register.end_registration') }}
              </div>
              <div class="welcome-sub">
                {{ $t('register.end_registration_sub') }}
              </div>
              <div class="welcome-container">
                <div class="logo-container">
                  <div class="logo-small" />
                </div>
                <div class="welcome-message">
                  {{ $t('register.end_registration_text') }}
                </div>
              </div>
              <Button
                class="register-page-button welcome-button"
                link
                :label="$t('register.access_1')"
                @click="openUrl(login_url)"
              />
            </div>
          </div>
          <div v-if="!email_verified">
            <div class="mb-5">
              <i class="far fa-envelope blue envelope" />
              <br>
              <h3>{{ $t('register.check_email') }}</h3>
              <p>{{ $t('register.check_email_text_1') }} {{ email }}{{ $t('register.check_email_text_2') }}</p>
              <p><small>{{ $t('register.check_email_sub') }}</small></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom_link">
      <span class="menu-text">
        {{ $t('register.already_account') }}
      </span>
      <Button
        class="menu-button"
        link
        :label="$t('register.access')"
        @click="openUrl(login_url)"
      />
    </div>
    <FooterRegister :language="language" />
  </div>
</template>

<script>
import { ref,onMounted,toRaw, watch } from "vue";
import { useAuthStore } from "../stores";
import { useI18n } from "vue-i18n";

import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox';
import Password from 'primevue/password';
import Dropdown from 'primevue/dropdown';
import axios from "axios";
import config from "../config";

import TopBarRegister from '@/components/sidebar/TopBarRegister.vue';
import FooterRegister from '@/components/sidebar/FooterRegister.vue';

import { userEmailExists,registerUserFrontend } from "../api/user";

import { googleSdkLoaded } from "vue3-google-login";

import { useLinkedIn, LinkedInCallback } from "vue3-linkedin-login";

export default {
  name: "Register",
  components: { Button, InputText, Checkbox, Password,Dropdown, TopBarRegister, FooterRegister },
  props: {
    contract: { type: String, default: "personal" },
    language: { type: String, default: "en" },
  },
  setup(props) {
    const { t,locale } = useI18n();
    const step1 = ref(true);
    const step2 = ref(false);
    const step3 = ref(false);
    const email = ref("");
    const password = ref("");
    const is_invalid_email = ref(false);
    const is_invalid_password = ref(false);
    const email_error = ref("");
    const password_error = ref("");
    const email_exists = ref(false);
    const email_verified = ref(false)
    const privacy_policy = ref(false);
    const news_letter = ref(false);
    const first_name = ref("");
    const first_name_disabled = ref(false)
    const picture = ref("");
    const last_name = ref("");
    const last_name_disabled = ref(false)
    const azienda = ref("");
    const register_by = ref("manual")

    const is_loading = ref(false)

    const identifichi = ref("");
    const dropdown_identifichi = ref([]);
    const dropdown_obbiettivo = ref([]);
    const dropdown_dipendenti = ref([]);
    const obbiettivo = ref("");
    const dipendenti = ref("");
    const first_name_valid = ref(true);
    const last_name_valid = ref(true);
    const identifichi_valid = ref(true);
    const obbiettivo_valid = ref(true);
    const azienda_valid = ref(true);
    const dipendenti_valid = ref(true);
    const privacy_policy_valid = ref(true);
    //const errored = computed(() => registerError.value !== "");
    const login_url = ref('')
    const authStore = useAuthStore()
    const linkTerms = ref("");
    const linkPrivacy = ref("");
    login_url.value = authStore.keycloak.createLoginUrl({redirectUri: config.FRONTEND_URL, locale: locale.value})
    const setLocale = (lang) => {
      console.log(lang)
      locale.value = lang
    };
    
    const openUrl = (url) =>{
        window.location.href = url
    }
    const setupDropdowns = () => {
    dropdown_identifichi.value = [{"value":"student","label":t('register.student')},
                                  {"value":"worker","label":t('register.worker')},
                                  {"value":"conselour","label":t('register.conselour')},
                                  {"value":"team_manager","label":t('register.team_manager')},
                                  {"value":"hr","label":t('register.hr')},
                                  {"value":"other","label":t('register.other')}]

    dropdown_obbiettivo.value = [{"value":"updated","label":t('register.updated')},
                                  {"value":"new_skills","label":t('register.new_skills')},
                                  {"value":"change_path","label":t('register.change_path')},
                                  {"value":"continous_formation","label":t('register.continous_formation')},
                                  {"value":"other","label":t('register.other')}]


    dropdown_dipendenti.value = [{"value":"1","label":t('register.1')},
                                  {"value":"2-9","label":t('register.2-9')},
                                  {"value":"10-49","label":t('register.10-49')},
                                  {"value":"50-249","label":t('register.50-249')},
                                  {"value":"+250","label":t('register.+250')}]
    }

    

    const validateFields = async() => {
        email_exists.value = false;
        if(email.value.length === 0){
          is_invalid_email.value = true;
          email_error.value = t('register.email_error');
        }
        else{
        is_invalid_email.value =  !/^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(String(email.value).toLowerCase());
        email_error.value = t('general.form.field_errors.email');
        }
        if(password.value.length === 0){
          is_invalid_password.value = true;
          password_error.value = t('register.password_error');
        }
      else{
        const containsUppercase = /[A-Z]/.test(password.value)
        const containsLowercase = /[a-z]/.test(password.value)
        const containsNumber = /[0-9]/.test(password.value)
        const containsSpecial = /[`!@#$%^&*()_\-+=[\]{};':"\\|,.<>/?~ ]/.test(password.value)
        const isLongEnough = password.value.length > 7
        is_invalid_password.value = !(containsUppercase && containsLowercase && containsNumber && containsSpecial && isLongEnough)
        console.log(is_invalid_password.value)
        password_error.value = "";
      }
      if(!is_invalid_email.value && !is_invalid_password.value){
      is_loading.value = true
      const response = toRaw(await userEmailExists(email.value));
      email_exists.value = response.data.exists;
      is_loading.value = false
      }
      if(!is_invalid_email.value && !is_invalid_password.value && !email_exists.value){
        moveToStep(2);
        window.fbq('track', 'InitiateCheckout');
      }
    }

    const validateFieldsStep2 = async() => {
      first_name_valid.value = true;
      last_name_valid.value = true;
      obbiettivo_valid.value = true;
      identifichi_valid.value = true;
      azienda_valid.value = true;
      dipendenti_valid.value = true;
      privacy_policy_valid.value = true;
        if(first_name.value.length === 0){
          first_name_valid.value = false;
        }
        if(last_name.value.length === 0){
          last_name_valid.value = false;
        }
        if(obbiettivo.value.length === 0){
          obbiettivo_valid.value = false;
        }
        if(identifichi.value.length === 0){
          identifichi_valid.value = false;
        }
        if(azienda.value.length === 0){
          azienda_valid.value = false;
        }
        if(dipendenti.value.length === 0){
          dipendenti_valid.value = false;
        }
        if(!privacy_policy.value){
          privacy_policy_valid.value = false;
        }
        if(first_name_valid.value && last_name_valid.value && obbiettivo_valid.value && identifichi_valid.value && azienda_valid.value && dipendenti_valid.value && privacy_policy_valid.value){
          // QUI CI VA LA REGISTRAZIONE DELL'UTENTE
          const user_data={
          email:email.value,
          password:password.value,
          first_name:first_name.value,
          last_name:last_name.value,
          picture:picture.value,
          email_verified:email_verified.value,
          identify:toRaw(identifichi.value).value,
          objective:toRaw(obbiettivo.value).value,
          company:azienda.value,
          company_size:toRaw(dipendenti.value).value,
          privacy_policy:privacy_policy.value,
          news_letter:news_letter.value,
          contract:props.contract,
          lang:locale.value
          }
          is_loading.value = true
          const status = await registerUserFrontend(user_data)
          console.log(status.data)
          if(status.data.status){
            moveToStep(3)
            window.fbq('track', 'CompleteRegistration');
          }
          is_loading.value = false
        }
    }

    const getGoogleData = ()=>{
      googleSdkLoaded(google => {
        google.accounts.oauth2
          .initCodeClient({
            client_id:
              "640870233267-bqeq5ef5gsr2v7d696ifusrvaaprq6a1.apps.googleusercontent.com",
            scope: "email profile openid",
            redirect_uri: "http://localhost:4000/auth/callback",
            callback: response => {
              if (response.code) {
                sendCodeToBackend(response.code);
              }
            }
          })
          .requestCode();
      });
    }

    const sendCodeToBackend = async(code) => {
      try {
        const response = await axios.post(
          "https://oauth2.googleapis.com/token",
          {
            code,
            client_id:
              "640870233267-bqeq5ef5gsr2v7d696ifusrvaaprq6a1.apps.googleusercontent.com",
            client_secret: "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",
            redirect_uri: "postmessage",
            grant_type: "authorization_code"
          }
        );

        const accessToken = response.data.access_token;

        // Fetch user details using the access token
        const userResponse = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        );

        if (userResponse && userResponse.data) {
          // Set the userDetails data property to the userResponse object
          email.value = userResponse.data.email
          first_name.value = userResponse.data.given_name
          last_name.value = userResponse.data.family_name
          picture.value = userResponse.data.picture
          email_verified.value = userResponse.data.email_verified
          register_by.value = "google"
          is_loading.value = true
          const res = toRaw(await userEmailExists(email.value));
          email_exists.value = res.data.exists;
          is_loading.value = false
          if(!email_exists.value){
            //Riabilitare per disattivare modifica dei campi con login da social
            // first_name_disabled.value = true;
            // last_name_disabled.value = true;
            moveToStep(2)
            window.fbq('track', 'InitiateCheckout');
          }
          //user.value = userResponse.data

        } else {
          // Handle the case where userResponse or userResponse.data is undefined
          console.error("Failed to fetch user details.");
        }
      } catch (error) {
        console.error("Token exchange failed:", error.response.data);
      }
    }

    const { linkedInLogin } =
      useLinkedIn({
        clientId: "7727bqlgrbghe9",
        clientSecret: "2jQS94wYZIOCfsZu",
        redirectUri: config.FRONTEND_URL + "/register/"+props.contract+'/'+props.language,
        onSuccess: async (code) => {
          console.log(code)
          //get user data... from django for CORS
          const response = await axios.post(
          config.BACKEND_URL + "/api/v2/linkedin/token/",
          {
            code:code,
            contract:props.contract,
            language:props.language
          }
        );
          email.value = response.data.user.email
          first_name.value = response.data.user.given_name
          last_name.value = response.data.user.family_name
          picture.value = response.data.user.picture
          email_verified.value = response.data.user.email_verified
          register_by.value = "linkedin"
          is_loading.value = true;
          const res = toRaw(await userEmailExists(email.value));
          email_exists.value = res.data.exists;
          is_loading.value = false
          if(!email_exists.value){
            //Riabilitare per disattivare modifica dei campi con login da social
            // first_name_disabled.value = true;
            // last_name_disabled.value = true;
            moveToStep(2)
            window.fbq('track', 'InitiateCheckout');
          }
        },
        scope: "email,profile,openid",
        onError: (error) => {
          console.log(error);
        },
      });

      onMounted(() => {
      LinkedInCallback();
      setLocale(props.language)
    });

    const moveToStep = (step) =>{
      switch(step){
        case 1:
          step1.value = true;
          step2.value = false;
          step3.value = false;
          break;
        case 2:
          step1.value = false;
          step2.value = true;
          step3.value = false;
          break;
        case 3:
          step1.value = false;
          step2.value = false;
          step3.value = true;
          break;
        default:
          step1.value = false;
          step2.value = false;
          step3.value = false;
          break;
      }
    }

    const setLinks = () => {
      linkTerms.value = (locale.value === 'en')?"https://www.viblio.com/terms-and-conditions/":"https://www.viblio.com/termini-e-condizioni/";
      linkPrivacy.value = (locale.value === 'en')?"https://www.viblio.com/privacy-policy-en/":"https://www.viblio.com/privacy-policy/";
    }
    setupDropdowns();
    setLinks();
    watch(locale, () => {
  console.log('locale', locale.value);
  if(locale.value != 'it' && locale.value!='en'){
    locale.value=props.language
  }
  console.log('locale', locale.value);
  setupDropdowns();
  setLinks();
  login_url.value = authStore.keycloak.createLoginUrl({redirectUri: config.FRONTEND_URL, locale: locale.value})
});
    return {
      step1,
      step2,
      step3,
      email,
      email_error,
      email_exists,
      email_verified,
      password,
      password_error,
      is_invalid_email,
      is_invalid_password,
      validateFields,
      moveToStep,
      linkedInLogin,
      getGoogleData,
      first_name,
      first_name_disabled,
      last_name,
      last_name_disabled,
      azienda,
      identifichi,
      dropdown_identifichi,
      obbiettivo,
      dropdown_obbiettivo,
      dipendenti,
      dropdown_dipendenti,
      privacy_policy,
      news_letter,
      validateFieldsStep2,
      first_name_valid,
      last_name_valid,
      identifichi_valid,
      obbiettivo_valid,
      azienda_valid,
      dipendenti_valid,
      privacy_policy_valid,
      login_url,
      openUrl,
      is_loading,
      linkTerms,
      linkPrivacy
    };
  },
};
</script>
<style scoped>
.link-back{
  cursor:pointer;
  color: var(--Primary-Blue-900, #162E91);
font-feature-settings: 'calt' off;
/* Paragraph/Normal/Bold */
font-family: "Nunito Sans";
font-size: 1rem;
font-style: normal;
font-weight: 700;
line-height: 1.5rem;
}
</style>
