<template>
  <Menubar
    :model="items"
    class="page-header"
  >
    <template #start>
      <a href="https://www.viblio.com/">
        <div
          title="Viblio"
          class="page-logo"
        />
      </a>
    </template>
    <template #item="{ item, props, hasSubmenu, root }">
      <a

        class="flex align-items-center"
        v-bind="props.action"
      >
        <span :class="item.icon" />
        <span class="ml-2">
          {{ item.label }}
        </span>
        <Badge
          v-if="item.badge"
          :class="{ 'ml-auto': !root, 'ml-2': root }"
          :value="item.badge"
        />
        <span
          v-if="item.shortcut"
          class="ml-auto border-1 surface-border border-round surface-100 text-xs p-1"
        >
          {{ item.shortcut }}
        </span>
        <i
          v-if="hasSubmenu"
          :class="['pi pi-angle-down', { 'pi-angle-down ml-2': root, 'pi-angle-right ml-auto': !root }]"
        />
      </a>
    </template>
    <template #end>
      <span class="menu-text">
        {{ $t('register.already_account') }}
      </span>
      <Button
        class="menu-button"
        link
        :label="$t('register.access')"
        @click="openUrl(login_url)"
      />
    </template>
  </Menubar>
</template>
<script>
import Menubar from 'primevue/menubar'
import Button from 'primevue/button';
import Badge from 'primevue/badge';
import { useAuthStore } from '../../stores';
import { ref,watch } from 'vue';
import config from '../../config';
import { useI18n } from "vue-i18n";


export default {
  name: "TopBarRegister",
  components: { Menubar, Button, Badge },
  props: { items: {type: Array, default: () => []},},
  setup(){
    const { locale } = useI18n();
    const login_url = ref('')
    const authStore = useAuthStore()
    login_url.value = authStore.keycloak.createLoginUrl({redirectUri: config.FRONTEND_URL,locale:locale.value})
    const openUrl = (url) =>{
        window.location.href = url
    }
    watch(locale, () => {
    console.log('locale', locale.value);
    login_url.value = authStore.keycloak.createLoginUrl({redirectUri: config.FRONTEND_URL,locale:locale.value})
    });
    return{ login_url,openUrl }
}
}
</script>
<style>
.page-header{
    width:83.66vw;
  margin: 1vh 8.33vw;
  background: var(--Grays-Gray-100, #F1F5F9);
  border:none;
}
.page-logo {
    width: 10rem;
    height: var(--space-10, 2.5rem);
    flex-shrink: 0;
    background: url(../../assets/logo-viblio-register.svg) no-repeat;
}
.menu-button{
    color: var(--Primary-Blue-900, #162E91);
    font-feature-settings: 'calt' off;
    font-family: "Nunito Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    text-decoration: none;
    border-radius: 2.5rem;
    background: var(--Primary-Blue-100, #DBEAFE);
    padding: 0.5rem 1rem;
}
.menu-text{
    padding: 0.5rem 1rem;
    position: relative;
    top: -0.6rem;
}
@media screen and (max-width: 600px) {
.p-menubar-end {
    display:none;
}
}
</style>
